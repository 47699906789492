<template>
	<b-overlay
		:show="isLoading"
		rounded="lg"
		opacity="0.6"
		spinner-variant="primary"
	>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4">
			<b-col cols="12">
				<router-link class="btn btn-sm btn-default" :to="{name: DiscountLogManagePath.name}">
					<em class="fa fa-backward"></em> Manage Discount Log
				</router-link>
			</b-col>
			<b-col v-if="item">
				<b-form @submit="onSubmit">
					<div class="card mt-4">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Detail Discount Log ({{ this.form.created }})
							</h5>
						</div>
						<div class="card-body">
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-form-group label="Email">
										<b-input-group-text id="email">
											{{ form.email ? form.email : "Unknown" }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Endpoint" v-if="form.data_url">
										<b-input-group-text id="data_url">
											{{ form.data_url }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Host">
										<b-input-group-text id="host_client" v-if="form.host_client">
											{{ form.host_client }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Method" v-if="form.method">
										<b-input-group-text id="method">
											{{ form.method }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Created" v-if="form.created">
										<b-input-group-text id="created">
											{{ form.created }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Request" v-if="form.data_request">
										<b-form-textarea :no-resize="true" rows="18" :value="form.data_request" :disabled="true"></b-form-textarea>
									</b-form-group>
									<b-form-group label="Response" v-if="form.data_response">
										<b-form-textarea :no-resize="true" rows="18" :value="form.data_response" :disabled="true"></b-form-textarea>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { DiscountLogManagePath } from "../../router/marketing";

export default {
	name: "detailDiscountLog",
	data() {
		return {
			DiscountLogManagePath,
			debounce: null,
			form: {
				id: null,
				email: null,
				data_url: null,
				host_client: null,
				method: null,
				created: null,
				data_request: null,
				data_response: null,
			},
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.discountLog.isError,
			isLoading: (state) => state.discountLog.isLoading,
			errorMessage: (state) => state.discountLog.errorMessage,
			successMessage: (state) => state.discountLog.successMessage,
			item: (state) => state.discountLog.item,
		}),
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		successMessage: function() {
			if (!this.successMessage) return;
			this.messageAlert("success", this.successMessage);
			Object.assign(this.$data, this.$options.data.call(this));
			this.$router.push(DiscountLogManagePath);
		},
		item: function() {
			if (!this.item) {
				this.messageAlert("error", "not found");
				return;
			}
			if (!Object.keys(this.item).length) return;
			this.setDetailDiscountLog();
		},
	},
	created() {
		this.form.id = this.$route.params.id;
		this.fetchDiscountLogById(this.form.id);
	},
	methods: {
		...mapActions("discountLog", ["fetchDiscountLogById"]),
		onSubmit(event) {
			event.preventDefault();
		},
		checkIfVariableIsString(variable) {
			return typeof variable === "string" || variable instanceof String;
		},
		setDetailDiscountLog() {
			const data = this.item;
			this.form.id = data?.id;
			this.form.email = data?.email;
			this.form.data_url = data?.data_url;
			this.form.host_client = data?.host_client;
			this.form.method = data?.method;
			this.form.created = data?.created;
			this.form.data_request = (this.checkIfVariableIsString(data?.data_request)) ? data?.data_request : JSON.stringify(data?.data_request, null, 2);
			this.form.data_response = (this.checkIfVariableIsString(data?.data_response)) ? data?.data_response : JSON.stringify(data?.data_response, null, 2);
		},
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
	},
};
</script>
